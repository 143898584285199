import React from 'react'
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material'
import { Content } from 'types'
import { useEpiContent } from '@trr/app-shell-data'
import AccessTime from '@mui/icons-material/AccessTimeFilled'

const ContactCard = () => {
  const { heading, defaultDescription } = useEpiContent<Content>()
  return (
    <>
      <Card data-testid="pending-contact-card" variant="outlined">
        <CardContent>
          <Typography variant="h6" component={'h2'} gutterBottom>
            {heading}
          </Typography>
          <List>
            <ListItem
              divider={false}
              sx={{ alignItems: 'start' }}
              disableGutters
            >
              <ListItemIcon>
                <Avatar
                  sx={{
                    backgroundColor: 'surface.green',
                    color: 'secondary.dark',
                  }}
                >
                  <AccessTime />
                </Avatar>
              </ListItemIcon>
              <ListItemText
                data-testid="radgivare-info"
                primary={defaultDescription}
                secondaryTypographyProps={{ component: 'div' }}
                secondary={
                  <Box mt={2}>
                    <Typography variant="body2" mb={1}>
                      Har du frågor är du välkommen att kontakta vårt Kundcenter
                    </Typography>
                    <Link href={`mailto:kontakt@trr.se`}>kontakt@trr.se</Link>
                    <Typography variant="body2" mt={1}>
                      020 - 87 78 77
                    </Typography>
                  </Box>
                }
              />
            </ListItem>
          </List>
        </CardContent>
      </Card>
    </>
  )
}

export default ContactCard
