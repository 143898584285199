import React from 'react'
import { useGetRadgivareQuery } from 'api'
import { useAuthentication } from '@trr/app-shell-data'
import { ContactCard, PendingContactCard, SelfServiceCard } from 'features'

const App = () => {
  const { sub } = useAuthentication()
  const { data, error, isLoading, isFetching } = useGetRadgivareQuery(sub, {
    skip: sub === null,
  })
  if (isLoading || isFetching) {
    return null
  }
  if (error) {
    throw error
  }
  if (data?.ingenTilldeladRadgivare) {
    return <PendingContactCard />
  }
  if (data?.oppenRadgivning) {
    return <SelfServiceCard />
  }
  return <ContactCard />
}

export default App
