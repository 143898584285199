import React from 'react'
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material'
import FaceRounded from '@mui/icons-material/FaceRounded'
import { Content } from 'types'
import { useAuthentication, useEpiContent } from '@trr/app-shell-data'
import { useGetRadgivareQuery } from 'api'

const SelfServiceCard = () => {
  const { sub } = useAuthentication()
  const { data } = useGetRadgivareQuery(sub, { skip: sub === null })
  const { heading } = useEpiContent<Content>()
  return (
    <>
      <Card data-testid="self-service-card" variant="outlined">
        <CardContent>
          <Typography variant="h6" component={'h2'} gutterBottom>
            {heading}
          </Typography>
          <List>
            <ListItem
              divider={false}
              sx={{ alignItems: 'start' }}
              disableGutters
            >
              <ListItemIcon>
                <Avatar
                  sx={{
                    backgroundColor: 'surface.green',
                    color: 'secondary.dark',
                  }}
                >
                  <FaceRounded />
                </Avatar>
              </ListItemIcon>
              <ListItemText
                data-testid="radgivare-info"
                primary="Du har valt Självservice"
                secondaryTypographyProps={{ component: 'div' }}
                secondary={
                  <Box mt={2}>
                    <Typography variant="body2" mb={1}>
                      Har du frågor är du välkommen att kontakta vårt Kundcenter
                    </Typography>
                    {data?.radgivare?.epost && (
                      <Link href={`mailto:${data?.radgivare.epost}`}>
                        {data?.radgivare.epost}
                      </Link>
                    )}
                    {data?.radgivare?.telefon && (
                      <Typography variant="body2" mt={1}>
                        {data?.radgivare.telefon}
                      </Typography>
                    )}
                  </Box>
                }
              />
            </ListItem>
          </List>
        </CardContent>
      </Card>
    </>
  )
}

export default SelfServiceCard
